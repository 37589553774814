* {
  box-sizing: border-box;
}

body {
  background-color: var(--bg-color);
}

.main-wrap {
  padding: 16px;
  box-shadow: var(--box-shadow);
  background-color: #fff;
  &.dashboard-wrap {
    padding: 8px;
  }
}

@media (max-width: 376px) {
  .main-wrap {
    padding: 8px;
  }
}

// hacks to be figured out later
.record-toggle-button {
  box-sizing: content-box;
}

.word-index {
  box-sizing: content-box;
}

.sidenav-group .mat-list-base {
  padding-top: 0;
}
