/* Add global styles styles and imports to this file. */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: Roboto, 'Helvetica Neue', sans-serif; */
  font-family: Lexend, sans-serif;
  font-size: 1.2rem;
}

a,
a:visited {
  color: var(--theme-color-primary);
  text-decoration: none;
}

.add-button-wrapper {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 101;
  flex-flow: row-reverse wrap-reverse;
  width: 100%;
}

.add-button-wrapper .add-button {
  margin-top: auto;
  padding: 7px 23px 23px 7px;
}

.block {
  display: block;
}

.exam-score-loading-tooltip.mat-tooltip {
  font-size: 16px;
  margin-bottom: -50px;
  padding-left: 20px;
  padding-right: 20px;
}

.full-width {
  width: 100%;
}

.icon-20 {
  font-size: 20px;
}

.inline-block {
  display: inline-block;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.material-symbols-filled {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.max-width-80 {
  max-width: 80px;
}

.max-width-112 {
  max-width: 112px;
}

.max-width-120 {
  max-width: 120px;
}

.max-width-180 {
  max-width: 180px;
}

.max-width-250 {
  max-width: 250px;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-480 {
  max-width: 480px;
}

.min-height-120 {
  min-height: 120px;
}

.mtx-dialog-title {
  letter-spacing: normal;
}

.red {
  color: var(--theme-color-warn);
}

.unicode {
  font-family: sans-serif;
}

@media (max-width: 600px) {
  .extra-margin {
    margin-bottom: 120px;
  }

  .extra-extra-margin {
    margin-bottom: 200px;
  }

  .min-height-800 {
    min-height: 800px;
  }
}
