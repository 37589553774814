@use '@angular/material' as mat;

// Order matters
// stylelint-disable material/no-unused-import
@use 'layout';
@use 'components';
@use 'tailwind';
// stylelint-enable material/no-unused-import

// Plus imports for other components in your app.

// @ng-matero/extensions
// https://github.com/ng-matero/extensions
@use '@ng-matero/extensions' as mtx;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-component-typographies();
@include mat.core();

// Custom purple theme for Rethink Reading.
// Light: #645ca8, Medium: #473d6c, Dark: #211646
$mat-aubergine: (
  50: #e4e3e9,
  100: #bcb9c8,
  200: #908ba3,
  300: #645c7e,
  400: #423962,
  500: #211646,
  600: #1d133f,
  700: #181037,
  800: #140c2f,
  900: #0b0620,
  A100: #775eff,
  A200: #4c2bff,
  A400: #2700f7,
  A700: #2300dd,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$primary: mat.define-palette($mat-aubergine, 500); // #211646
$accent: mat.define-palette(mat.$yellow-palette, A400); // #ffea00
$warn: mat.define-palette(mat.$red-palette, A200); // #ff5252

$app-typography: mat.define-typography-config(
  $font-family: 'Lexend, sans-serif',
);

// Create the theme object (a Sass map containing all of the palettes).
// $theme: mat.define-light-theme($primary, $accent, $warn);
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: $app-typography,
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
@include mat.all-legacy-component-themes($app-theme);

// @ng-matero/extensions
// https://github.com/ng-matero/extensions
@include mtx.all-component-themes($app-theme);

:root {
  --theme-color-primary: #211646;
  --theme-color-accent: #ffea00;
  --theme-color-warn: #ff5252;
  --theme-color-secondary: #40c4ff;
  --primary-color: #211646;
  --primary-font: rgb(31 31 31 / 99%);
  --secondary-font: rgb(31 31 31 / 66%);
  --tertiary-font: rgb(31 31 31 / 40%);
  --box-shadow: 0 0 0 1px rgb(49 49 93 / 3%), 0 2px 5px 0 rgb(49 49 93 / 10%),
    0 1px 2px 0 rgb(0 0 0 / 8%);
  --bg-color: #f5f6fa; // background
  --borders: rgb(0 0 0 / 12%); // border color
  --phonics: #ff879b;
  --phonics-alt: #ffc1ce;
  --reading: #40c4ff;
  --reading-alt: #8ae3fc;
  --comprehension: #c979df;
  --comprehension-alt: #e9b9f6;
  --fluency: #ffbd7b;
  --fluency-alt: #ffe1ba;
  --multiplechoice: #ffd836;
  --completed-color: #78be20;
}

.theme-color-primary,
.theme-color-primary:visited {
  color: var(--theme-color-primary);
}

.theme-color-accent,
.theme-color-accent:visited {
  color: var(--theme-color-primary-alt);
}

.theme-color-warn,
.theme-color-warn:visited {
  color: var(--theme-color-warn);
}

.theme-color-secondary,
.theme-color-secondary:visited {
  color: var(--theme-color-secondary);
}

.theme-background-primary {
  background-color: var(--theme-color-primary);
}

.theme-background-accent {
  background-color: var(--theme-color-accent);
}

.theme-background-warn {
  background-color: var(--theme-color-warn);
}

.theme-background-secondary {
  background-color: var(--theme-color-secondary);
}
