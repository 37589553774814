@use 'sass:color';

$borders: rgb(0 0 0 / 12%); // border color
$primary-color: #211646;

.component-background {
  background-repeat: repeat;
  background-size: 90%;
  margin: -16px;
  min-height: calc(100% + 32px);
  overflow-x: hidden;
}

.phonics-bg-pattern {
  background-image: url('/assets/images/backgrounds/space-pattern_dan7ay.png');
  background-color: var(--phonics);
}

.reading-bg-pattern {
  background-image: url('/assets/images/backgrounds/space-pattern_dan7ay.png');
  background-color: var(--reading);
}

.fluency-bg-pattern {
  background-image: url('/assets/images/backgrounds/space-pattern_dan7ay.png');
  background-color: var(--fluency);
}

.comprehension-bg-pattern {
  background-image: url('/assets/images/backgrounds/space-pattern_dan7ay.png');
  background-color: var(--comprehension);
}

.space-bg-pattern {
  background-image: url('/assets/images/backgrounds/pattern_space.svg');
  background-size: 66%;
}

.space-bg-bleed {
  background: rgb(27 10 140);
  background: linear-gradient(
    106deg,
    rgb(27 10 140 / 100%) 0%,
    rgb(10 10 43 / 100%) 100%
  );
  background: linear-gradient(
    106deg,
    rgb(27 10 140 / 100%) 0%,
    rgb(10 10 43 / 100%) 100%
  );
  background: linear-gradient(
    196deg,
    rgb(27 10 140 / 100%) 0%,
    rgb(10 10 43 / 100%) 100%
  );
}

.rethink-reading-basic-card {
  background-color: #fff;
  box-shadow: var(--box-shadow);
  margin: 8px;
  p {
    line-height: 1.6;
  }
}

.banner {
  padding: 16px;
  h2 {
    color: #fff;
    margin: 0;
  }
  p {
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bordercard-head {
  padding: 16px;
  border-bottom: 1px solid $borders;
  h2 {
    margin: 0;
  }
  p {
    margin: 0;
  }
}

.bordercard {
  border: 1px solid $borders;
  border-radius: 2px;
}

main-wrap img {
  max-width: 100%;
  height: auto;
}

.bg-phonics-alt {
  background-color: var(--phonics-alt);
}

.bg-comprehension-alt {
  background-color: var(--comprehension-alt);
}

.bg-reading-alt {
  background-color: var(--reading-alt);
}

.bg-fluency-alt {
  background-color: var(--fluency-alt);
}

.bg-phonics {
  background-color: var(--phonics);
}

.bg-comprehension {
  background-color: var(--comprehension);
}

.bg-reading {
  background-color: var(--reading);
}

.bg-fluency {
  background-color: var(--fluency);
}

.bg-main {
  background-color: var(--primary-color);
}

.bg-main-alt {
  background-color: color.adjust($primary-color, $alpha: -0.3);
}

.text-center {
  text-align: center;
}

.card-padding {
  padding: 16px;
}

// bootstrap override
.row {
  margin-left: inherit !important; // stylelint-disable-line declaration-no-important
  margin-right: inherit !important; // stylelint-disable-line declaration-no-important
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes float-6 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-6px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes orbit {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

@keyframes wave-3 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(3deg);
  }
}

.pointer {
  cursor: pointer;
}

.nav-profile {
  img {
    width: 56px;
    height: 56px;
    margin-right: 4px;
  }
}
