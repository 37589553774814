// Tailwind

// stylelint-disable scss/at-rule-no-unknown
@tailwind base;
@tailwind components;
@tailwind utilities;
// stylelint-enable scss/at-rule-no-unknown

// Tailwind overrides

// Remove form field border styles to improve compability to Angular Material components.
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  border: none;
  box-shadow: none;
}

// Apply base styles to restore Material Design defaults.
// See: https://tailwindcss.com/docs/adding-custom-styles#adding-base-styles

@layer base {
  h1 {
    @apply font-light;
    @apply text-8xl;

    letter-spacing: -1.5px;
  }
  h2 {
    @apply font-light;
    @apply text-6xl;

    letter-spacing: -0.5px;
  }
  h3 {
    @apply font-normal;
    @apply text-5xl;

    letter-spacing: 0;
  }
  h4 {
    @apply font-normal;

    font-size: 34px;
    letter-spacing: 0.25px;
  }
  h5 {
    @apply font-normal;
    @apply text-2xl;

    letter-spacing: 0;
  }
  h6 {
    @apply font-medium;
    @apply text-xl;

    letter-spacing: 0.15px;
  }
}
